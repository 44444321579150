import React from "react";
import {
  FaSpotify,
  FaApple,
  FaYoutube,
  FaSoundcloud,
  FaEnvelope,
  FaTiktok,
  FaTwitch,
  FaInstagram,
} from "react-icons/fa";
import "./App.css";

function App() {
  return (
    <div className="App">
      {/* Sticky Text */}
      <div className="sticky-text">
        <div className="top-left">AUTREIYAS</div>
        <div className="top-right">11.22.24 LATE TO THE PARTY</div>
      </div>

      <div className="container">
        {/* YouTube Embed */}
        <iframe
          src="https://www.youtube.com/embed/videoseries?list=OLAK5uy_lrJ373uHnfgpNf4N1yaPpMm2MygED_4qo"
          title="LTTP ON YOUTUBE"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        {/* Icon Links */}
        <div className="icons">
          <a
            href="https://open.spotify.com/album/275gYsAACtwpAKH9wghx2j?si=r1GB3vxiSkW1TAdErH0W0g"
            target="_blank"
            rel="noopener noreferrer"
            className="spotify"
          >
            <FaSpotify className="icon spotify" />
          </a>
          <a
            href="https://music.apple.com/us/album/late-to-the-party/1780833675"
            target="_blank"
            rel="noopener noreferrer"
            className="apple"
          >
            <FaApple className="icon apple" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC0rEK_dw5tf6kMolr2-zLlA"
            target="_blank"
            rel="noopener noreferrer"
            className="youtube"
          >
            <FaYoutube className="icon youtube" />
          </a>
          <a
            href="mailto:treyasbooking@gmail.com"
            className="email"
          >
            <FaEnvelope className="icon email" />
          </a>
          <a
            href="https://www.tiktok.com/@autreiyas"
            target="_blank"
            rel="noopener noreferrer"
            className="tiktok"
          >
            <FaTiktok className="icon tiktok" />
          </a>
          <a
            href="https://www.twitch.tv/autreiyas"
            target="_blank"
            rel="noopener noreferrer"
            className="twitch"
          >
            <FaTwitch className="icon twitch" />
          </a>
          <a
            href="https://www.instagram.com/autreiyas"
            target="_blank"
            rel="noopener noreferrer"
            className="instagram"
          >
            <FaInstagram className="icon instagram" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
